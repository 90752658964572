import { css } from '@emotion/react'
import MenuIcon from '@mui/icons-material/Menu'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { globalBlueGreen } from '~/modules/AppLayout/Colors'
import { LayoutModule } from '~/modules/SDK/Layout/layout/LayoutModule'
import type { FuiDrawer } from '~/pages/heineken_template/components/FuiDrawer'

/**
 * # 改用 {@link FuiDrawer}
 *
 * @deprecated
 */
export const LayoutDrawerToggle = memo<
  ReactProps<{
    store: InstanceType<typeof LayoutModule>['store']
  }>
>(function LayoutDrawerToggle(props) {
  const state = useSnapshot(props.store)

  return (
    <MenuIcon
      css={css`
        color: ${globalBlueGreen.bg500};
        ${state.DrawerToggle.customCSS};
      `}
      className={props.className}
      onClick={event => {
        props.store.Drawer.open = !props.store.Drawer.open ? true : false
      }}
    />
  )
})
