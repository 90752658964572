import { css } from '@emotion/react'
import { Drawer } from '@mui/material'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import { LayoutModule } from '~/modules/SDK/Layout/layout/LayoutModule'
import type { FuiDrawer } from '~/pages/heineken_template/components/FuiDrawer'

/**
 * # 改用 {@link FuiDrawer}
 *
 * @deprecated
 */
export const LayoutDrawer = memo<
  ReactProps<{
    store: InstanceType<typeof LayoutModule>['store']
  }>
>(function LayoutDrawer(props) {
  const state = useSnapshot(props.store)

  return (
    <Drawer
      css={css`
        ${state.Drawer.customCSS};
      `}
      className={props.className}
      anchor={state.Drawer.position}
      open={state.Drawer.open}
      onClose={() => {
        props.store.Drawer.open = false
      }}
      ModalProps={{ disablePortal: true }}
    >
      {props.children}
    </Drawer>
  )
})
